import { createRouter, createWebHistory } from 'vue-router'
import Init from "@/views/Init.vue";
import Home from "@/views/Home.vue";
import store from "@/store";
import Manage from "@/views/Shops/Manage.vue";
import CreateShop from "@/views/Shops/CreateShop.vue";
import SelectCategory from "@/views/Shops/SelectCategory.vue";
import SelectCategoryProduct from "@/views/Products/SelectCategory.vue";
import Shops from "@/views/Shops/Shops.vue";
import CreateProduct from "@/views/Products/CreateProduct.vue";
import Product from "@/views/Products/Product.vue";
import EditShop from "@/views/Shops/EditShop.vue";
import EditProduct from "@/views/Products/EditProduct.vue";
import ViewCategory from "@/views/Categories/ViewCategory.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import Profile from "@/views/Profile/Profile.vue";
import Locale from '@/views/Profile/Locale.vue';

const routes = [
  {
    path: '/',
    name: 'Initial',
    component: Init
  },
  {
    path: '/home',
    name: 'HomePage',
    component: Home
  },
  {
    path: '/shops/manage',
    name: 'Управление магазинами',
    component: Manage,
  },
  {
    path: '/shops/create/first',
    name: 'Выбор категории для магазина',
    component: SelectCategory,
  },
  {
    path: '/shops/create/second',
    name: 'Создание магазина',
    component: CreateShop,
  },
  {
    path: '/shops/view/:id',
    name: 'Страница магазина',
    component: Shops,
  },
  {
    path: '/shops/edit/:id',
    name: 'Редактирование магазина',
    component: EditShop,
  },
  {
    path : "/products/create/first",
    name : "Выбор категорий для товара",
    component: SelectCategoryProduct,
  },
  {
    path : "/products/create/second",
    name : "Создание товара",
    component: CreateProduct,
  },
  {
    path : '/products/view/:id',
    name : "Просмотр товара",
    component: Product,
  },
  {
    path : '/products/edit/:id',
    name : "Редактирование товара",
    component: EditProduct
  },
  {
    path : '/categories/:id',
    name : "Просмотр категории",
    component: ViewCategory
  },
  {
    path : '/profile',
    name : "Профиль",
    component: Profile
  },
  {
    path : '/settings/locale',
    name : "Локализация",
    component: Locale
  },
  { path: '/:pathMatch(.*)*', name: "Не найдено", component: NotFoundPage }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if(to.path !== '/' && !store.state.userInfo) {
    next('/')
    console.log("guard")
  }
  else{
    console.log("no guard")
    next();
  }
})

export default router

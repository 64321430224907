<script>


export default {
  name: "CatsWrapper",
  components: {},
  methods : {
    viewCat(id,title){
      this.$router.push('/categories/' + id + '?title=' + title);
    }
  }
}
</script>

<template>
  <div class="grid-container">
    <div class="grid-item digital-services enabled" @click="viewCat('66afb5c03639e3434ba0ca2d',$t('categories.digital'))"><img :src="`${$cdn}main/digital.png`" alt="">{{$t('categories.digital')}}</div>
    <div class="grid-item business-startups enabled"  @click="viewCat('66afb5d63639e3434ba0ca30',$t('categories.business'))"><img :src="`${$cdn}main/business.png`" alt="">{{$t('categories.business')}}</div>
    <div class="grid-item work enabled" @click="viewCat('66afb5ad3639e3434ba0ca27',$t('categories.jobs'))"><img :src="`${$cdn}main/jobs.png`" alt="">{{$t('categories.jobs')}}</div>
    <div class="grid-item art-handmade enabled" @click="viewCat('66afb5e43639e3434ba0ca33',$t('categories.art'))"><img :src="`${$cdn}main/art.png`" alt="">{{$t('categories.art')}}</div>
    <div class="grid-item real-estate enabled" @click="viewCat('66afb5a33639e3434ba0ca24',$t('categories.building'))"><img :src="`${$cdn}main/buildings.png`" alt="">{{$t('categories.building')}}</div>
    <div class="grid-item market enabled" @click="viewCat('66afb5b53639e3434ba0ca2a',$t('categories.market'))"><img :src="`${$cdn}main/market.png`" alt="">{{$t('categories.market')}}</div>
    <div class="grid-item bulletin-board enabled" @click="viewCat('66afb58b3639e3434ba0ca21',$t('categories.desk'))"><img :src="`${$cdn}main/desk.png`" alt="">{{$t('categories.desk')}}</div>
    <div class="alert">

    </div>
  </div>

</template>

<style scoped>
.alert{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  backdrop-filter: blur(4px);

}
.alert span{
  font-weight: bold;
}
.grid-item{
  font-size: 13px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.grid-container{
  display: grid;
  padding-top: 5px;
  position: relative;
  height: max-content;

  grid-template-columns: repeat(2, 1fr);

  grid-template-rows: repeat(4, 144px);
}
.digital-services{
  grid-column: 1;
  grid-row: 1;
}
.business-startups{
  grid-column: 2;
  grid-row: 1;
}
.work{
  grid-column: 1;
  grid-row: 2;
}
.art-handmade{
  grid-column: 2;
  grid-row: 2;
}
.real-estate{
  grid-column: 1;
  grid-row: 3;
}
.market{
  grid-column: 1;
  grid-row: 4;
}
.enabled{
  position: relative;
  z-index: 5;
}
.bulletin-board{
  grid-column: 2;
  grid-row: 3;

}
img{
  border-radius: 20px;
}

</style>
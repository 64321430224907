<template>
    <div class="loading-block">
      <div class="logotype">
        <video  playsinline autoplay width="100" muted  loop>
          <source src="@/assets/loading.webm" type="video/webm">
        </video>
      </div>
      <p v-if="string == ''">{{$t('system.hello')}}, {{initData?.user.firstName}}!</p>
      {{string}}  
    </div>
</template>
<script>


import {mockTelegramEnv, parseInitData, initMiniApp, initUtils, initCloudStorage} from '@telegram-apps/sdk';
export default {
    name : "InitPage",
    data(){
      return{
        initData : null,
        string : "",
      }
    },
    async mounted() {
      try {
        this.initData = parseInitData(window.Telegram.WebApp.initData);
        
        let parsed = this.initData;
        let unparsed = window.Telegram.WebApp.initData;

        mockTelegramEnv({
          themeParams: {
            accentTextColor: '#6ab2f2',
            bgColor: '#ffffff',
            buttonColor: '#5288c1',
            buttonTextColor: '#ffffff',
            destructiveTextColor: '#ec3942',
            headerBgColor: '#ffffff',
            hintColor: '#708499',
            linkColor: '#6ab3f3',
            secondaryBgColor: '#ffffff',
            sectionBgColor: '#ffffff',
            sectionHeaderTextColor: '#6ab3f3',
            subtitleTextColor: '#708499',
            textColor: '#f5f5f5',
          },
          initData: parsed,
          unparsed,
          version: '7.2',
          platform: 'tdesktop',
        });
        const [miniApp] = initMiniApp();

        window.Telegram.WebApp.expand();

        let request = await this.$api.post("auth", {
          telegram_data: window.Telegram.WebApp.initData
        });

        

        if(request.token){
        
          localStorage.setItem("token",request.token);
          this.$store.state.userInfo = request;

          if(this.$sentry){
            this.$sentryObj.setUser({ id: request._id, username: `${request.username}|${request.id}`});
          }
          let getUserShops = await this.$api.get('shops/me');

          if(getUserShops instanceof Array){
            this.$store.state.userShops = getUserShops;
          }
          console.log("gettings");
          const cloudStorage = initCloudStorage();
          console.log(cloudStorage);
          if(process.env.NODE_ENV == "production"){
            let isFirstWas = await cloudStorage.get('first-run-was')
            if(!isFirstWas){
              await cloudStorage.set('first-run-was',true)
              this.$router.push('/settings/locale/');
            }
            else{
              this.$i18n.locale = await cloudStorage.get('locale');
            }
          }
          
          if(parsed.startParam){
            let param = parsed.startParam;
            let command = param.split('place')[1];
            if(command.includes('shop')){
              let shop = command.split('shop')[1];
              this.$router.push('/shops/view/' + shop + '?from=/home');
            }
            if(command.includes('product')){
              let prod = command.split('product')[1];
              this.$router.push('/products/view/' + prod + '?from=/home');
            }
          }
          else{
            this.$router.push('/home');
          }

        }
        else{
          if(request.error == "USER_HASH"){
            let utils = initUtils();
            utils.openTelegramLink("https://t.me/Tagito_bot")
            miniApp.close();
          }
          this.string = request.message;
        }
      }
      catch (e){
        console.log(e)
        this.string = this.$t('system.error_on_init')
      }



    }
  }
</script>
<style scoped>
.loading-block{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column; gap: 20px;
  width: 100vw;
}
.logotype{
  border-radius: 15px;
  overflow: hidden;
  height: max-content;
  width: max-content;
}
video{
  width: 110px;
  height: 110px;
}
@keyframes loading {
  0%{
    rotate: 0deg;

  }
  30%{
    transform: scale(1.1);
  }
  45%{
    rotate: 0;
    scale: 1;
  }
  50%{
    rotate: 360deg;

  }
  80%{

  }
  100%{
    rotate: 360deg;

  }
}
p{
  max-width: 300px;
  word-break: break-all;
}
</style>
<script>
export default {
  name: "ShopInList",
  props : {
    data : {
      type : Object
    },
    skeleton : {
      type : Boolean,
      default : false,
    }
  }
}
</script>

<template>
  <div :class="(skeleton) ? 'skeleton cat-in-list w-full animate-pulse' : 'cat-in-list'">
<!--    <div :class="(skeleton) ? 'skeleton avatar' : 'avatar'"><img :src="`${$cdn}categories/icons/${data.slug}.svg`" alt=""></div>-->
    <span :class="(skeleton) ? 'skeleton-text' : ''">{{$t(`categories.${data.slug}`)}}</span>
  </div>
</template>

<style scoped>
.avatar{
  height: 35px;
  width: 35px;
  background: linear-gradient(90deg, #0181A8 0%, #02AEBA 100%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cat-in-list{
  background: #fff;
  display: flex;
  box-shadow: 0px 0px 10px 0px #0000000D;
  font-size: 15px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 15px;
}

.skeleton.avatar{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #bbbbbb;
}
.skeleton.cat-in-list{
  background: #d2d2d2;
  display: flex;
  box-shadow: 0px 0px 10px 0px #0000000D;
  font-size: 15px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 15px;
}
.skeleton-text{
  padding: 5px 40px;
  background: #bbbbbb;
  border-radius: 24px;
}
</style>